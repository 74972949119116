<template>
  <v-overlay :value="getAppInfo == ''" v-if="getAppInfo == ''" color="#fff">
    <v-progress-circular color="#63043b" indeterminate size="64"></v-progress-circular>
  </v-overlay>
  <router-view v-else />
</template>

<script>
import firebaseInitialize from './firebase'
import { mapGetters } from 'vuex'
export default {
  name: 'App',
  created() {
    firebaseInitialize()
  },
  computed: {
    ...mapGetters([
      'getAppInfo'
    ]),
  },
  mounted() {
    this.$store.dispatch("fetchAppInfo")
  }
};
</script>




























<style src="./styles/app.scss" lang="scss"/>


