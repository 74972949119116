import router from "@/router";

const BASE_URL =
  "https://myvtu.com/new/api/f8b5a269fbf9b176af18d730b41de8c49d6cdc59";
const MAIN_URL = "https://api.oneappgo.com/v1";
const user = {
  state: {
    user: "",
    userDetails: "",
    products: "",
    prices: "",
    upgradePrices: "",
    appinfo: "",
    verinfo: "",
    token: null,
    banks: null,
    transDetails: null,
    pinOtp: "",
    cards: null,
  },
  getters: {
    getUser: (state) => state.user,
    getUserDetails: (state) => state.userDetails,
    getProducts: (state) => state.products,
    getPrices: (state) => state.prices,
    getUpgradePrices: (state) => state.upgradePrices,
    getAppInfo: (state) => state.appinfo,
    getVerinfo: (state) => state.verinfo,
    getToken: (state) => state.token,
    getBanks: (state) => state.banks,
    getTransDetails: (state) => state.transDetails,
    getPinOtp: (state) => state.pinOtp,
    getCards: (state) => state.cards,
  },
  mutations: {
    //update user
    updateUser: (state, details) => (state.user = details),

    //update user details
    updateUserDetails: (state, details) => (state.userDetails = details),

    //update products
    updateProducts: (state, details) => (state.products = details),

    //update prices
    updatePrices: (state, details) => (state.prices = details),

    //update upgrade prices
    updateUpgradePrices: (state, details) => (state.upgradePrices = details),

    //update appinfo
    updateAppInfo: (state, details) => (state.appinfo = details),

    //update verinfo
    updateVerinfo: (state, details) => (state.verinfo = details),

    //update products
    updateToken: (state, details) => (state.token = details),

    //update banks
    updateBanks: (state, details) => (state.banks = details),

    //update trans Details
    updateTransDetails: (state, details) => (state.transDetails = details),

    //update pin otp
    updatePinOtp: (state, details) => (state.pinOtp = details),

    //update cards
    updateCards: (state, details) => (state.cards = details),
  },
  actions: {
    //log user in
    loginUser: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("email", data.email);
        formData.append("pword", data.password);
        formData.append("env", "web");

        if (data.apptoken) {
          formData.append("apptoken", data.apptoken);
        }

        const res = await fetch(`${BASE_URL}/login`, {
          method: "POST", // or 'PUT'
          body: formData,
        });
        return res;
      } catch (e) {
        return {
          data: {
            msg: e.message,
            status: false,
          },
        };
      }
    },

    //register user
    registerUser: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("lname", data.lname);
        formData.append("fname", data.fname);
        formData.append("phoneno", data.phone);
        formData.append("uname", data.uname);
        formData.append("email", data.email);
        formData.append("referby", data.referby);
        formData.append("pword", data.password);
        formData.append("env", "web");

        if (data.apptoken) {
          formData.append("apptoken", data.apptoken);
        }

        const res = await fetch(`${BASE_URL}/register`, {
          method: "POST", // or 'PUT'
          body: formData,
        });
        console.log(res);
        return res;
      } catch (e) {
        return {
          data: {
            msg: e.message,
            status: false,
          },
        };
      }
    },

    //fetch user details
    fetchUserCredentials: async (context) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("uid", context.getters.getUser.userid);
        formData.append("seckey", "59884ba6534213f1f719b4a4723ecd1475dd5f8c");

        await fetch(`${BASE_URL}/getinfo`, {
          method: "POST",
          body: formData,
        })
          .then((response) => response.json())
          .then(async (response) => {
            if (response.status === false) {
              window.alert("A new user session detected");
              window.sessionStorage.clear();
              window.localStorage.clear();
              return router.push({ name: "login" }, () => {
                window.sessionStorage.clear();
                window.location.reload();
              });
            }
            return context.commit("updateUserDetails", response.userdata);
          });
      } catch (e) {
        console.log(e.message);
      }
    },

    //general
    getRequest: async (context, url) => {
      try {
        const res = await fetch(`${BASE_URL}/${url}`, {
          method: "GET", // or 'PUT'
        });
        return res;
      } catch (e) {
        return {
          data: {
            msg: e.message,
            status: false,
          },
        };
      }
    },

    postRequest: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("uid", context.getters.getUser.userid);
        formData.append("seckey", "59884ba6534213f1f719b4a4723ecd1475dd5f8c");
        formData.append("appkey", "59884ba6534213f1f719b4a4723ecd1475dd5f8c");
        formData.append("env", "web");
        for (const key in data.data) {
          formData.append(key, data.data[key]);
        }
        const res = await fetch(`${BASE_URL}/${data.url}`, {
          method: "POST", // or 'PUT'
          body: formData,
        });

        const response = await res.json().then((response) => {
          if (
            response.status === false &&
            (response.msg === "User not logged in" ||
              response.msg === "Unable to fetch user data - not logged in")
          ) {
            window.alert("A new user session detected");
            window.sessionStorage.clear();
            window.localStorage.clear();
            return router.push({ name: "login" }, () => {
              window.sessionStorage.clear();
              window.location.reload();
            });
          }
          return response;
        });
        return response;
        // return res;
      } catch (e) {
        return {
          data: {
            msg: e.message,
            status: false,
          },
        };
      }
    },

    getRequest2: async (context, url) => {
      try {
        const res = await fetch(`${MAIN_URL}/${url}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.getters.getAppInfo["ONEAPPPUBLIC"]}`,
          },
        });
        const response = await res.json().then((response) => {
          return response;
        });
        return response;
      } catch (e) {
        return {
          data: {
            msg: e.message,
            status: false,
          },
        };
      }
    },

    //fetch products
    fetchProducts: async (context) => {
      await context
        .dispatch("postRequest", {
          url: "products",
          data: {},
        })
        .then((response) => {
          if (response.MTNDATA.length > 0) {
            return context.commit("updateProducts", response);
          }
        })
        .catch((e) => console.log(e));
    },

    //fetch prices
    fetchPrices: async (context) => {
      await context
        .dispatch("postRequest", {
          url: "prices",
          data: {},
        })
        .then((response) => {
          if (response.status) {
            return context.commit("updatePrices", response.transactions);
          }
        })
        .catch((e) => console.log(e));
    },

    //fetch upgrade prices
    fetchUpgradePrices: async (context) => {
      await context
        .dispatch("postRequest", {
          url: "upgradeprices",
          data: {},
        })
        .then((response) => {
          if (response.status) {
            return context.commit("updateUpgradePrices", response.data);
          }
        })
        .catch((e) => console.log(e));
    },

    //fetch app info
    fetchAppInfo: async (context) => {
      await context
        .dispatch("postRequest", {
          url: "appinfo",
          data: {},
        })
        .then((response) => {
          if (response.status) {
            return context.commit("updateAppInfo", response.appdata);
          }
        })
        .catch((e) => console.log(e));
    },

    //fetch verify info
    fetchVerifyInfo: async (context) => {
      await context
        .dispatch("postRequest", {
          url: "getverinfo",
          data: {},
        })
        .then((response) => {
          if (response.status) {
            return context.commit("updateVerinfo", response.userdata);
          }
        })
        .catch((e) => console.log(e));
    },

    //fetch banks
    fetchBanks: async (context) => {
      await context
        .dispatch("getRequest2", "banklist")
        .then((response) => {
          if (response.status) {
            return context.commit("updateBanks", response.banklist);
          }
        })
        .catch((e) => console.log(e));
    },
  },
};

export default user;
