import { initializeApp } from "firebase/app";
import { getMessaging, onMessage, getToken } from "firebase/messaging";
import { getAnalytics, logEvent } from "firebase/analytics";
import store from "./store";

function requestPermission() {
  console.log("Requesting permission...");
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      console.log("Notification permission granted. " + permission);
    }
  });
}

export default async function firebaseInitialize() {
  const firebaseConfig = {
    apiKey: "AIzaSyAvPOfewo7uWyWlaVg8GcwcKCJNABg3lm8",
    authDomain: "myvtu-4076b.firebaseapp.com",
    projectId: "myvtu-4076b",
    storageBucket: "myvtu-4076b.appspot.com",
    messagingSenderId: "195224391149",
    appId: "1:195224391149:web:026a54da2b48aaa4b7abe4",
    measurementId: "G-841S75HF22",
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);

  // Initialize Firebase Cloud Messaging and get a reference to the service
  const messaging = getMessaging(app);
  const analytics = getAnalytics(app);
  logEvent(analytics, "notification_received");

  getToken(messaging, {
    vapidKey:
      "BFR6U6dwF6zUMU2C5G5hceZXNGP0XHCChjNmtT8ptRhj8ZEHVd-EzGoB2sYJJsWLW8zC94ORgTgXuTyYSYA4S8Q",
  })
    .then((currentToken) => {
      if (currentToken) {
        store.commit("updateToken", currentToken);
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        requestPermission();
      }
    })
    .catch();

  onMessage(messaging, (payload) => {
    var notificationTitle = payload.notification.title;
    var notificationOptions = {
      body: payload.notification.body,
      icon: "./assets/logo.png",
      link: "https://app.myvtu.com",
    };

    var notification = new Notification(notificationTitle, notificationOptions);
    notification.onclick = function (event) {
      notification.close();
      console.log(event);
    };
  });
}
