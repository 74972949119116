const site = {
  namespace: true,
  state: {
    drawer: false,
  },
  getters: {
    DRAWER_STATE(state) {
      return state.drawer;
    },
  },
  mutations: {
    //drawer
    toggleDrawer(state) {
      state.drawer = !state.drawer;
    },
  },
  actions: {
    //togle drawer
    TOGGLE_DRAWER({ commit }) {
      commit("toggleDrawer");
    },
  },
};

export default site;
